// export default {

// 	// api请求地址
// 	baseUrl: 'http://ziyuan.niuteam.cn/',

// 	// 图片域名
// 	imgDomain: 'http://ziyuan.niuteam.cn/',

// 	// 腾讯地图key
// 	 mpKey: '',

// 	// 客服wws
// 	 webSocket: 'ws://ziyuan.niuteam.cn/ws',

// 	// api安全
// 	apiSecurity: false,

// 	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
// 	pingInterval: 1500,

// 	// 公钥
// 	publicKey: ``,

// }
export default {

	// api请求地址
	baseUrl: 'https://www.mling.cn/',

	// 图片域名
	imgDomain: 'https://www.mling.cn/',

	// 腾讯地图key
	 mpKey: '',

	// 客服wws
	 webSocket: 'wss://www.mling.cn/wss',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}